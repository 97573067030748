export class MicTest {
    constructor(setFile, setRecorder, setPrevRecord) {
        this.setFile = setFile;
        this.setRecorder = setRecorder;
        this.setPrevRecord = setPrevRecord;
        this.recordingData = [];
    }

    start() {
        this.setRecorder('on');
    }

    save(data) {       
        this.recordingData.push(data);
    }

    error(message) {
        message && APP.UI.alertMessage(message);
        this.stop();
    }

    stop() {
        if (this.recordingData.length > 0) {
            const blobUrl = URL.createObjectURL(this.recordingData[0]);
            this.setFile(blobUrl);    
            
            this.setRecorder('off');
            this.setPrevRecord(null);
        }        
    }
}